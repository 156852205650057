<template>
  <div class="Login">
    <div class="left">
      <video src='https://aivaca.oss-cn-shanghai.aliyuncs.com/data/xunhua//20221127/video.mp4' muted loop autoPlay></video>
    </div>
    <div class="right">
      <div class="right-con" >
        <img class="loginlogo" alt="" />
        <el-input placeholder="请输入用户名" v-model="username" prefix-icon="el-icon-user"></el-input>
        <div style="margin-top: 16px"></div>
        <el-input placeholder="请输入密码" v-model="password" show-password prefix-icon="el-icon-unlock"></el-input>
        <div style="margin-top: 32px"></div>
        <el-button type="primary" @click="onclick_login">登录</el-button>
      </div>
    </div>
    <Vcode :show="showVcode" @success="onVcodeSuccess" @close="onVcodeClose" />
  </div>
</template>

<script>
import Vcode from "vue-puzzle-vcode";
import {configUrl} from './router/routeUrl';
import { POST } from './api/index'
export default {
  components: {
    Vcode,
  },
  name: 'login',
  data() {
    return {
      username: '',
      password: '',
      showVcode: false
    }
  },
  methods: {
    onclick_login() {
      if (!this.username) {
        this.$message.error('请输入用户名');
        return
      }
      if (!this.password) {
        this.$message.error('请输入密码');
        return
      }
      
      this.showVcode = true
    },
    async  onVcodeSuccess() {
      this.showVcode = false
      const res = await POST({
        url: 'admin/adminLogin', 
        body: {
          passWord: this.password,
          userName: this.username,
        },
      })
      if (res.status == 200) {
        localStorage.setItem('token', res.content.token)
        this.$message({
          message: '登录成功',
          type: 'success',
          onClose: () => {
            window.location.href = configUrl('/check')
          }
        });
      } else {
        this.$message.error(res.msg);
      }
      
    },
    onVcodeClose() {},
  }
}
</script>

<style >
.Login {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to left top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
}
.form {
  width: 900px;
  height: 560px;
  display: flex;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 25px 45px rgba(0, 0, 0, 0.1);
}

.left {
  width: 500px;
  height: 560px;
}

.left video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.right {
  width: 400px;
  height: 560px;
  background: rgba(255, 255, 255, .1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.right-con {
  width: 70%;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.login-form-button {
  width: 100%;
  margin-top: 30px !important;
  height: 36px;
  background-image: linear-gradient(to left top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);;
  background-clip: padding-box;
  padding: 0;
  border: 0;
}
.login-form-button:focus {
  width: 100%;
  margin-top: 30px !important;
  height: 36px;
  background-image: linear-gradient(to left top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);;
  background-clip: padding-box;
  padding: 0;
  border: 0;
  opacity: 0.5;
}
</style>

